import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import useStoryblok from "../lib/storyblok";

import Layout from "../components/global/layout/Layout";
import Seo from "../components/global/seo/Seo";

import useSiteSettings from "../hooks/useSiteSettings";
import LanguageProvider from "../i18n/LanguageProvider";
import * as styles from "./404.module.scss";
import SiteLogo from "../../static/images/start-logo-for-linkedin-featured-simplified.jpg"

import Page from "../templates/Page";
import StoryPage from "../templates/StoryPage";
import MediaPage from "../templates/MediaPage";
import EventPage from "../templates/EventPage";
import Landing from "../templates/Landing";




function buildNavigation(entries) {
  var navigation = [];
  entries.forEach((entry) => {
    if (!entry.node.field_exclude_in_navigation_boolean) {
      //Add to global data
      navigation.push({
        path: `/${rewriteSlug(entry.node.full_slug)}`,
        name: entry.node.name,
        urlRedirect: entry.node.field_url_redirect_string,
        menuCaption: entry.node.field_menu_caption_string,
        customPath: entry.node.path,
        order: entry.node.field_nav_order_string
      });
    }
  });
  navigation.sort((a,b)=>{
    if(a.order) {
      if(b.order) {
        if(a.order === b.order) {
          return a.name > b.name ? 1 : -1
        }
        return a.order > b.order ? 1 : -1
      }
      return 1
    } else if (b.order) {
      return -1
    } else {
      return a.name > b.name ? 1 : -1
    }
  })
  return navigation;
}

function rewriteSlug(slug) {
  const defaultLanguage = "en/";
  let newSlug = slug;
  newSlug = newSlug.replace("home", "");
  newSlug = newSlug.replace(defaultLanguage, "");
  return newSlug;
}

const NotFoundPage = ({ location }) => {
  const results = useStaticQuery(
    graphql`
      query get404data {
        stories: allStoryblokEntry(filter: {field_component: {in: ["page"]}}) {
          edges {
            node {
              id
              name
              slug
              field_exclude_in_navigation_boolean
              field_exclude_in_sitemap_boolean
              field_exclude_from_search_external_boolean
              field_exclude_from_search_internal_boolean
              field_nav_order_string
              field_component
              full_slug
              content
              path
              field_menu_caption_string
              field_url_redirect_string
            }
          }
        },
      }
    `
  );
  const settings = useSiteSettings("en");

  const nav = buildNavigation(results.stories.edges).filter((item)=>{
    return !item.path.includes('/fr/') && item.name !== 'Home'});
  let story = useStoryblok( null, location);
  const blokSeo = {
    pageType: "404",
    full_slug: "",
    slug: location.pathname.replace('/', ''),
    title: "404 Page Not Found",
    description: "Sorry, the page you are looking for couldn't be found.",
    og_image: SiteLogo,
    og_title: "404 Page Not Found",
    og_description: "Sorry, the page you are looking for couldn't be found.",
    twitter_image: SiteLogo,
    twitter_title: "404 Page Not Found",
    twitter_description: "Sorry, the page you are looking for couldn't be found.",
  }

  
  // Only Dev
  if(story && story.content){
    let pageContext = {
      story,
      navigation: nav,
      locale: 'en',
      globalSettings: settings
    }
    switch( story.content.component){
      case "page":
        return (<Page pageContext={pageContext} location={location}></Page>);
      case "story":
        return(<StoryPage pageContext={pageContext} location={location}></StoryPage>)
      case "media_brochure" :
        return (<MediaPage pageContext={pageContext} location={location}></MediaPage>);
      case "media_press_release" :
        return (<MediaPage pageContext={pageContext} location={location}></MediaPage>);
      case "media_video" :
        return (<MediaPage pageContext={pageContext} location={location}></MediaPage>);
      case "media_webinar" :
        return (<MediaPage pageContext={pageContext} location={location}></MediaPage>);
      case "event":
        return (<EventPage pageContext={pageContext} location={location}></EventPage>)
      case "landing":
        return (<Landing pageContext={pageContext} location={location}></Landing>)
      default:
    }
  }
  
  //Actual 404
  return (
    <LanguageProvider locale={"en"}>
      <Layout navigation={nav} settings={settings} locale={"en"} location={location}>
        <Seo blok={blokSeo} location={location} locale={"en"}/>
          <div className={styles.notFound}>
            <h1>Page Not Found</h1>
            <h2>Sorry, the page you are looking for couldn't be found.</h2>
          </div>
      </Layout>
    </LanguageProvider>
  );
};

export default NotFoundPage;
