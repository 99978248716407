import * as React from "react"

import useStoryblok from "../lib/storyblok"
import LanguageProvider from "../i18n/LanguageProvider"

import Layout from "../components/global/layout/Layout"
import Seo from "../components/global/seo/Seo"
import MediaHero from "../components/internal/mediaHero/MediaHero"
import DynamicComponent from "../components/DynamicComponent"
import NarrowContainer from "../components/internal/narrowContainer/NarrowContainer"

import "bootstrap/dist/css/bootstrap.min.css";

const Page = ({ pageContext, location }) => { 
  let story = pageContext.story !== undefined && pageContext.story && pageContext.story !== "" ? pageContext.story : {}
  story = useStoryblok(story, location)
  let components = []
  if(story.content.body) {
    components = story.content.body.map(blok => {
      return (<DynamicComponent blok={blok} key={blok._uid} locale={pageContext.locale} />)
    })
  }
  let preheader = {
    date: story.content.date ? story.content.date : null,
    endDate: story.content.end_date ? story.content.end_date: null,
    time: story.content.event_time ? story.content.event_time : null,
    location: story.content.location ? story.content.location : null
  }
 
  return (
    <LanguageProvider locale={pageContext.locale}>
      <Layout navigation={pageContext.navigation} locale={pageContext.locale} settings={pageContext.globalSettings} location={location}>
          <style>{`body {
            background-color: #f1f1f1;
          }`}</style>
        <Seo blok={story && story.content ? story : null} locale={pageContext.locale} location={location} />
         <MediaHero headline={story.content.event_title} image={story.content.hero_image} preheader={preheader} />
         <NarrowContainer>
            { components }
          </NarrowContainer>
      </Layout>
    </LanguageProvider>
)}
 
export default Page